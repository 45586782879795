import { Config } from './config';

const config: Config = {
  title: 'Workshop',
  titleSub: 'Future Insight',
  name: 'nlin3d',
  greeting: 'Welkom bij de 3D workshop van Future Insight!',
  showLogo: true,
  showLinkToViewers: true,
};

export default config;
