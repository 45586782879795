/* eslint-disable @typescript-eslint/no-unsafe-return */
import mapConfigs from './mapconfigs';

/**
 * voorbeeld    : OK
 * visualisatie : no styles defined
 * zoeken       : no search doable
 * schaduw      : OK
 * ondergrond   : OK
 * zicht        : OK
 * meten        : OK (niet veranderd)
 * planner      : OK
 * bim modellen : OK
 * instellingen : OK
 * tekenen in 3D: OK
 * attributen   : OK
 */

const exercises = [
  {
    example: true,
    id: 'intro',
    title: 'Voorbeeld',
    subtitle: 'T3D viewer',
    img: '/exercises/totaal3d/intro/main.png',
    rating: 0,
    mapConfig: 't3dSprint',
    customCss: '/templates/totaal3d.css',
    tags: [
      'T3D',
      'Voorbeeld',
    ],
    description: [
      'Dit is één van de viewers van het programma totaal 3 dimensionaal waar deze e-learning op werkt. In deze viewer zijn verschillende voorbeelden uit het programma te raadplegen, ze worden uitgelegd in de storymap.',
      'Klik <a href="https://www.totaal3d.nl/viewer/storymap/" target="_blank">hier</a> om naar de storymap te gaan.',
    ],
    hints: [],
  },
  {
    id: 'schaduw',
    'title': 'Schaduw',
    'subtitle': 'Haalt de schaduw van het Torentje de overkant van de hofvijver?',
    'img': '/exercises/totaal3d/schaduw/Schaduw.png',
    'rating': 2,
    mapConfig: 't3dSchaduw',
    customCss: '/templates/totaal3d.css',
    'tags': [
      'T3D',
      'Tools',
      'Den Haag',
    ],
    'description': [
      'Schaduwanalyses zijn eenvoudig te doen binnen een 3D omgeving. Laten we in dit geval eens kijken hoe de schaduw van het Torentje gedurende het jaar valt.',
      'Begin maar eens te kijken hoe de schaduw er vandaag om 10:00 uur uitzag.',
      'Bekijk vervolgens hoe de schaduw gedurende vandaag bewoog/gaat bewegen en tenslotte hoe het er gedurende het jaar uitziet rond 10:00 uur.',
      'Kun je ontdekken of de schaduw van het Torentje de overkant van de hofvijver kan halen?',
    ],
    'hints': [
      {
        'description': "Belangrijkste is hier dat je weet dat 'controls' op meerdere plekken (kunnen) zitten in de viewer. Voor deze oefening moet je rechtsboven zijn:",
        'img': '/exercises/totaal3d/schaduw/Schaduw_control.png',
      },
      {
        'description': 'Nu de schaduw (hopelijk) aan staat, kun je met het instellingsknopje parameters gaan veranderen:',
        'img': '/exercises/totaal3d/schaduw/Schaduw_instellingen.png',
      },
      {
        'description': "En vergeet niet de 'schaduw player' af te spelen. Je kunt de instellingen tijdens het afspelen nog verschuiven.",
        'img': '/exercises/totaal3d/schaduw/Schaduw_player.png',
      },
    ],
  },
  {
    id: 'ondergrond',
    'title': 'Ondergrond',
    'subtitle': 'Is er nog ruimte voor de mollen?',
    'img': '/exercises/totaal3d/ondergrond/Ondergrond.png',
    'rating': 5,
    mapConfig: 't3dOndergrond',
    customCss: '/templates/totaal3d.css',
    'tags': [
      'T3D',
      'Tools',
      'Riool',
      'Den Haag',
    ],
    'description': [
      'Als je over straat loopt, zie je in één oogopslag hoe vol de buitenruimte is. Maar hoe zit dat onder de grond? Kun je zomaar een verkeersbord neerzetten zonder iets te raken?',
      'Er zijn verschillende manieren om de ondergrond te bekijken. Je kunt het terrein transparant maken of een gat in de grond graven.',
      'In deze viewer is de riolering te bekijken.',
    ],
    'hints': [
      {
        'description': 'Kijk bij de tools welke opties je hebt om onder de grond te kijken.',
        'img': '/exercises/totaal3d/ondergrond/Ondergrond_tool.png',
      },
      {
        'description': 'Als een echte mol kun je zelf ook onder de grond. Dit doe je in de transparante terrein modus.',
        'img': '/exercises/totaal3d/ondergrond/Ondergrond_transparant.png',
      },
    ],
  },
  {
    id: 'zicht',
    'title': 'Zichtbaarheid',
    'subtitle': 'Kun je de Beurs van Berlage zien vanaf Amsterdam Centraal?',
    'img': '/exercises/totaal3d/zicht/Zicht.png',
    'rating': 3,
    mapConfig: 't3dZicht',
    customCss: '/templates/totaal3d.css',
    'tags': [
      'T3D',
      'Tools',
      'Amsterdam',
    ],
    'description': [
      'Als je zelf voor Amsterdam Centraal staat weet je wel wat je allemaal ziet. Maar dit kun je ook simuleren in een 3D omgeving.',
      'Met de zichtanalyse tool kun je op elke locatie in T3D bekijken wat er allemaal zichtbaar is.',
      'Kun je bijvoorbeeld een stukje van de Beurs van Berlage zien vanaf het Centraal Station? En wat zie je aan de achterkant van CS als je bijv. 2km ver kan kijken?',
    ],
    'hints': [
      {
        'description': 'Bij de tools is er een optie om een zichtanalyse uit te voeren.',
        'img': '/exercises/totaal3d/zicht/Zicht_tool.png',
      },
      {
        'description': 'Waarschijnlijk is de afstand die je kunt analyseren nog te klein. Deze kun je tot 2000m uitbreiden.',
        'img': '/exercises/totaal3d/zicht/Zicht_instellingen.png',
      },
    ],
  },
  {
    id: 'meten',
    title: 'Meten is weten',
    subtitle: 'Kan een vrachtwagen onder de Liefdesbrief door?',
    img: '/exercises/totaal3d/liefdesbrief/liefdesbrief.png',
    rating: 3,
    mapConfig: 't3dMeten',
    customCss: '/templates/totaal3d.css',
    tags: [
      'T3D',
      'Tools',
      'Delft',
    ],
    description: [
      "Vrachtwagens in Nederland zijn maximaal 4 meter hoog. Als het goed is zijn viaducten dus hoger... Laten we eens kijken of dat in het geval van 'de Liefdesbrief' ook zo is.",
      "Gebruik hiervoor in eerste instantie de 'hoogteprofiel-tool' en verifieer het door het juiste te meten met de 'meten-tool'",
      "Als dit toch al makkelijk gaat, hoe hoog is de torenspits van 'Nieuwe Kerkhof 1' in Groningen? En hoeveel m2 is Nieuwe Kerkhof (OpenStreetMap, groen)? En hoe lang is de schaduw van de torenspits op 30 augustus 2022 om 12:00 uur 's middags?",
    ],
    hints: [
      {
        'description': 'De Liefdesbrief is een viaduct in de buurt van Ikea Delft...',
        'img': '',
      },
      {
        'description': "Zoek anders via 'Zoeken' boven in het menu naar 'liefdesbrief'",
        'img': '',
      },
      {
        'description': 'Als het goed is heb je de Liefdesbrief nu gevonden. Selecteer de hoogteprofiel tool via het toolsmenu en volg de tips die verschijnen. En? Is de Liefdesbrief hoger dan 4 meter bij de rijbanen?',
        'img': '/exercises/totaal3d/liefdesbrief/toolsmenu.png',
      },
      {
        'description': "Let op! Binnen de 'hoogteprofiel-tool' kun je ook nog meten!",
        'img': '/exercises/totaal3d/liefdesbrief/hoogteprofiel.png',
      },
      {
        'description': 'Een andere optie is om een meting te doen. Selecteer de meet-tool via het toolsmenu en voer de juiste meting uit. Klopt het resultaat met de vorige meting?',
        'img': '',
      },
    ],
  },
  {
    id: 'planner-bim',
    title: 'Plannen',
    subtitle: 'Plaats het onbrekende gebouw',
    img: '/exercises/totaal3d/bim/schependom_bim.png',
    rating: 5,
    mapConfig: 't3dBim',
    customCss: '/templates/totaal3d.css',
    tags: [
      'T3D',
      'Tools',
      'Planner',
      'Nijmegen',
    ],
    description: [
      'Iedereen die iets met BIM doet kent \'Schependomlaan 22\' in Nijmegen.',
      'Maar niet iedereen weet dat dit voorbeeld-gebouw ook daadwerkelijk is gebouwd! Het zit echter nog niet in onze 3D Bag laag. Tijd om daar verandering in aan te brengen met behulp van de plannings module in de 3D viewer.',
      'In deze proefopstelling zit ook de plannermodule. Via deze module kun je gebouwen toevoegen aan de viewer.Op die manier kun je zien wat de impact is van een gebouw op de omgeving. In deze opgave werken we met een paar voorbeeldbestanden',
      'Voor deze oefening zijn logingegevens nodig. Deze zijn op te vragen door een mailtje te sturen naar <a href="mailto:support@futureinsight.nl?subject=Login voor planner e-learning&body=Graag ontvang ik een login voor de planner van de e-learning module van totaal3d.nl. Met vriendelijke groet, ...">support@futureinsight.nl</>.',
    ],
    hints: [{
      description: 'Download als eerste in ieder geval Schependomlaan (GLTF) hierboven. Deze gaan we (proberen te) plaatsen op de juiste plek in Nijmegen. In het tools-menu is nu alleen de planner beschikbaar. Klik deze aan en log in met de verkregen gebruikersnaam/wachtwoord combinatie.',
      img: '/exercises/totaal3d/bim/planner_button.png',
    }, {
      description: 'Na het inloggen, kun je een nieuw project aanmaken en andere projecten zien. Maak er eentje aan met een herkenbare naam. Iets als [voornaam]-Schependom oid.',
      img: '/exercises/totaal3d/bim/new_project.png',
    }, {
      description: "Klik op het potloodje om het project te bewerken. Vervolgens kan via 'Planning object importeren' het zojuist gedownloade model geupload worden.",
      img: '/exercises/totaal3d/bim/uploaded.png',
    }, {
      description: 'Er staat een waarschuwing bij het model. Het model kon namelijk niet automatisch geplaatst worden. Klik op het model om het vervolgens met een klik op de kaart op de juiste plek te zetten.',
      img: '/exercises/totaal3d/bim/place_model.png',
    }, {
      description: 'Nadat het model geplaatst is, staat het nog niet helemaal correct. Gebruik de diverse tools om dit te veranderen. Vergeet niet de wijzigingen op te slaan.',
      img: '/exercises/totaal3d/bim/place_tools.png',
    }, {
      description: 'Het precies plaatsen is best lastig. Een rotatie van 70 ziet er goed uit, en hier zijn wat instellingen voor de locatie.',
      img: '/exercises/totaal3d/bim/coordinates.png',
    }, {
      description: 'Om het af te maken kun je een standaard zichtpunt aanmaken. Volg de aanwijzingen. Als het goed is, ziet het er nu ongeveer uit als dit voorbeeld.',
      img: '/exercises/totaal3d/bim/viewpoint.png',
    }, {
      description: 'En zo ziet Schependomlaan 22 er in het echt uit:',
      img: '/exercises/totaal3d/bim/schependom_real.png',
    }],
    downloads: [{
      name: 'Schependom (GLTF)',
      url: '/exercises/totaal3d/bim/models/schependomlaan_gltf.glb',
    }, {
      name: 'Simpel huis (GLTF)',
      url: '/exercises/totaal3d/bim/models/simpel_huis.glb',
    }, {
      name: 'Huis (GLTF)',
      url: '/exercises/totaal3d/bim/models/huis.glb',
    }],
  },
  {
    id: 'instellingen',
    title: 'Instellingen',
    subtitle: 'Kijk eens door een gekleurde bril...',
    img: '/exercises/totaal3d/settings/gekleurdebril.png',
    rating: 1,
    mapConfig: 't3dInstellingen',
    customCss: '/templates/totaal3d.css',
    tags: [
      'T3D',
      'Gadget',
      'Den Haag',
    ],
    description: [
      'De 3D kaart is op verschillende manieren te bekijken. Hoe ziet de wereld er uit in zwart/wit? Of door een nachtkijker? En wat gebeurt er als je de kwaliteit wat lager zet?',
      'Vragen, vragen, vragen..',
    ],
    hints: [{
      description: 'Het begint allemaal met \'Instellingen\'',
      img: '/exercises/totaal3d/settings/instellingen.png',
    }, {
      description: 'De rest zou eigenlijk voor zich moeten spreken. Probeer gewoon wat uit..',
      img: '/exercises/totaal3d/settings/glow.png',
    }],
  },
  {
    id: 'tekenen',
    title: 'Tekenen in 3D',
    subtitle: 'Teken een parkeergarage voor Future Insight',
    img: '/exercises/totaal3d/parkeergarage/parkeergarage.png',
    rating: 4,
    mapConfig: 't3dTekenen',
    customCss: '/templates/totaal3d.css',
    tags: [
      'T3D',
      'Tools',
      'Zwolle',
    ],
    description: [
      'Future Insight is booming. Tijd om een parkeergarage in de buurt van het kantoor te hebben.',
      'Probeer er eentje te tekenen, net als in het voorbeeld.',
    ],
    hints: [{
      description: "Het kantoor van Future Insight is makkelijk te vinden via de 'Inhoud'..",
    }, {
      description: 'In dit geval is er maar 1 tool beschikbaar. De teken-tool:',
      img: '/exercises/totaal3d/parkeergarage/tekentool.png',
    }, {
      description: 'Tekenen in 3D is best lastig. Bekijk de wereld eens van boven.',
      img: '/exercises/totaal3d/parkeergarage/topview.png',
    }, {
      description: "Elke parkeergarage begint met een grondvlak. Dat we later uitrekken naar boven. Selecteer de 'vlak-tool':",
      img: '/exercises/totaal3d/parkeergarage/vlaktool.png',
    }, {
      description: 'Dubbelklik bij het zetten van het laatste punt, of klik op het vinkje om het vlak af te maken:',
      img: '/exercises/totaal3d/parkeergarage/vinkje.png',
    }, {
      description: "Selecteer het zojuist getekende vlak door de 'selectie-tool' te gebruiken en het vlak aan te klikken:",
      img: '/exercises/totaal3d/parkeergarage/selectie.png',
    }, {
      description: 'Trek het model uit, plaats het op het maaiveld, verander de kleur etc. Als het goed is, staat er nu iets wat zomaar eens een parkeergarage kan worden.',
    }],
  },
  {
    id: 'attributen',
    title: 'Attributen',
    subtitle: 'Wanneer is de Euromast eigenlijk gebouwd?',
    img: '/exercises/totaal3d/attributen/euromast.png',
    rating: 1,
    mapConfig: 't3dAttributen',
    customCss: '/templates/totaal3d.css',
    tags: [
      'T3D',
      'Attributen',
      'Rotterdam',
    ],
    description: [
      'Één van de basisfuncties van een 3D digital twin is het beschikbaar maken van attributen van 3D modellen. In dit geval gaan we op zoek naar het bouwjaar van de Euromast. Of is dat gewoon parate kennis?',
    ],
    hints: [{
      description: "Begin maar eens met het 'Zoeken' naar de Euromast.",
    }, {
      description: "In tegenstelling tot de Hef (een brug), is de Euromast is een gebouw, voorlopig staan die wellicht nog niet aan. Kijk eens bij 'Inhoud'.",
    }, {
      description: 'Geven all gebouw lagen hetzelfde resultaat? Hopelijk wel, want het is alleen telkens een andere representatie van dezelfde data!',
      img: '/exercises/totaal3d/attributen/layers.png',
    }, {
      description: 'Volgens de pop-up is de Euromast in 1961 gebouwd. Volgens Wikipedia een iets eerder trouwens :-)',
      img: '/exercises/totaal3d/attributen/euromast_wiki.png',
    }],
  },
  {
    id: 'bimmodellen',
    title: 'BIM - CityGML',
    subtitle: 'Bekijk een BIM model',
    img: '/exercises/totaal3d/bimmodellen/haringstraat.png',
    rating: 1,
    mapConfig: 't3dBimmodellen',
    customCss: '/templates/totaal3d.css',
    tags: [
      'T3D',
      'BIM',
      'CityGML',
      'Den Haag',
    ],
    description: [
      'Hier hebben we enkele BIM modellen (IFC) geconverteerd naar CityGML',
      'Kijk eens rond in het menu om de verschillende modellen in verschillende "Levels of Detail" (LoD) te bekijken',
    ],
    hints: [{
      description: "Kijk onder 'Inhoud', om de diverse modellen te vinden.",
    }, {
      description: 'De BIM modellen staan op een plek waar de 3D BAG ook al een pand laat zien. Zet de 3D BAG laag uit om het model goed te kunnen bekijken.',
      img: '/exercises/totaal3d/bimmodellen/hidden.png',
    }],
  },
];

const mapped = exercises.map((ex: any) => ({
  ...ex,
  config: mapConfigs[ex.mapConfig as never],
}));

export default mapped;
