import nederlandin3d from './nederlandin3d.json';
import liefdesbrief from './liefdesbrief.json';
import rotterdam from './rotterdam.json';
import rotterdam_full from './rotterdam_full.json';
import denhaag from './denhaag.json';
import schaduw from './schaduw.json';
import waariswally from './waariswally.json';
import instellingen from './instellingen.json';
import bimdenhaag from './bimdenhaag.json';
import parkeergarage from './parkeergarage.json';
import sensoren from './sensoren.json';
import attributen from './attributen.json';
import bim from './bim.json';
import ondergrond from './ondergrond.json';
import intro from './intro.json';
import samenwerkworkshop from './samenwerkworkshop.json';

import t3dStoryMap from './t3d_storymap.json';
import t3dViewer from './t3d_viewer.json';
import t3dSprint from './t3d_sprint.json';
import t3dSchaduw from './t3d_schaduw.json';
import t3dOndergrond from './t3d_ondergrond.json';
import t3dZicht from './t3d_zicht.json';
import t3dMeten from './t3d_meten.json';
import t3dBim from './t3d_bim.json';
import t3dInstellingen from './t3d_instellingen.json';
import t3dTekenen from './t3d_tekenen.json';
import t3dAttributen from './t3d_attributen.json';
import t3dBimmodellen from './t3d_bimmodellen.json';

export default {
  nederlandin3d,
  liefdesbrief,
  rotterdam,
  denhaag,
  schaduw,
  waariswally,
  instellingen,
  bimdenhaag,
  parkeergarage,
  sensoren,
  attributen,
  bim,
  ondergrond,
  intro,
  samenwerkworkshop,
  rotterdam_full,

  t3dViewer,
  t3dSprint,
  t3dStoryMap,
  t3dSchaduw,
  t3dOndergrond,
  t3dZicht,
  t3dMeten,
  t3dBim,
  t3dInstellingen,
  t3dTekenen,
  t3dAttributen,
  t3dBimmodellen,
};
