/* eslint-disable @typescript-eslint/no-unsafe-return */
import mapConfigs from './mapconfigs';

const exercises = [
  {
    example: true,
    id: 'intro',
    title: 'Voorbeeld',
    subtitle: 'Standaard viewer',
    img: '/exercises/nlin3d/intro/main.png',
    rating: 0,
    mapConfig: 'intro',
    customCss: '/templates/nederlandin3d.css',
    tags: [
      'Voorbeeld',
    ],
    description: [
      'Dit is een voobeeld viewer. Kijk rustig rond en klik eens op wat knoppen.',
    ],
    hints: [],
  },
  {
    id: 'rotterdam-visualisatie',
    title: 'Visualisatie',
    subtitle: 'Een andere weergave kan nieuwe inzichten bieden.',
    img: '/exercises/nlin3d/rotterdam/visualisatie/visualisatie.png',
    rating: 3,
    mapConfig: 'rotterdam_full',
    customCss: '/templates/rotterdam.css',
    tags: [
      'Rotterdam',
      'Layers',
      'Styling',
    ],
    description: [
      'Net zoals in de standaard 2D-kaarten, kun je in een 3D omgeving ook de visualisatie van bijvoorbeeld gebouwen aanpassen. In 3D kan dit weer tot andere inzichten leiden die in 2D misschien net zo snel op zouden vallen. En het is ook mogelijk een realistische weergave van de buitenwereld na te bootsen.',
      'Kijk maar eens of je de kleur van de gebouwen aan kunt passen. Welke gegevens van gebouwen kunnen allemaal gevisualiseerd worden?',
      'En kun je ook een realistische weergave van de buitenwereld op je scherm toveren? Misschien vind je nog andere lagen dan de gebouwen die daarbij kunnen helpen.',
    ],
    hints: [
      {
        description: 'Kijk eens in de inhoud welke mogelijkheden je ziet.',
      },
      {
        description: 'Bij de laag met gebouwen staat een knop waarmee je de visualisatie kunt aanpassen.',
        img: '/exercises/nlin3d/rotterdam/visualisatie/visualisatie_style.png',
      },
      {
        description: 'Om een realistische weergave te maken moet je lagen aanzetten met de term "textuur".',
      },
      {
        description: 'Van gebouwen, bruggen en bomen zijn getextureerde versies beschikbaar.',
      },
      {
        description: 'Om het plaatje compleet te maken kun je de basislaag Luchtfoto aanzetten.',
      },
    ],
  },
  {
    id: 'rotterdam-zoeken',
    title: 'Zoeken',
    subtitle: 'Waar staan de oudste gebouwen en hoogste bomen?',
    img: '/exercises/nlin3d/rotterdam/zoeken/zoeken.png',
    rating: 5,
    mapConfig: 'rotterdam_full',
    customCss: '/templates/rotterdam.css',
    tags: [
      'Rotterdam',
      'Tools',
    ],
    description: [
      'Het is wel zo handig dat je kunt zoeken naar een locatie zodat je niet zelf door het 3D-model heen hoeft te bewegen. Maar je kunt ook met een zoekopdracht bepaalde objecten opzoeken.',
      'Kun jij onze werklocatie Oostmaaslaan 53 snel vinden? En wat gebeurd er als je naar De Kuip zoekt?',
      'Niet alleen locaties zijn te vinden, maar je kunt ook op zoek gaan naar de oudste gebouwen en de hoogste bomen. Kun je ze vinden?',
      'Het is ook mogelijk zelf een zoekopdracht te maken. Dit vraagt echter wel wat meer kennis van CityGML.',
    ],
    hints: [
      {
        description: 'Locaties kun je opzoeken met de zoekfunctie boven in beeld.',
        img: '/exercises/nlin3d/rotterdam/zoeken/zoeken_zoek.png',
      },
      {
        description: 'Als je naar eigenschappen van gebouwen of bomen wil zoeken gebruik je deze knop in de tools.',
        img: '/exercises/nlin3d/rotterdam/zoeken/zoeken_zoekopdracht.png',
      },
      {
        description: 'Zorg er voor dat de laag waarin je wil gaan zoeken aan staat.',
      }, {
        description: 'Er is een aantal zoekopdrachten voorbereid.',
        img: '/exercises/nlin3d/rotterdam/zoeken/zoeken_bouwjaar.png',
      },
      {
        description: 'Klik op één van de resultaten om daar naar toe te vliegen.',
        img: 'Zoeken_resultaten.png',
      },
    ],
  },
  {
    id: 'rotterdam-schaduw',
    'title': "Schaduw R'dam",
    'subtitle': 'Haalt de schaduw van De Rotterdam de overkant?',
    'img': '/exercises/nlin3d/rotterdam/schaduw/Schaduw.png',
    'rating': 2,
    mapConfig: 'rotterdam_full',
    customCss: '/templates/rotterdam.css',
    'tags': [
      'Rotterdam',
      'Tools',
    ],
    'description': [
      'Schaduwanalyses zijn eenvoudig te doen binnen een 3D omgeving. Laten we in dit geval eens kijken hoe de schaduw van De Rotterdam gedurende het jaar valt.',
      'Begin maar eens te kijken hoe de schaduw er vandaag om 10:00 uur uitzag.',
      'Bekijk vervolgens hoe de schaduw gedurende vandaag bewoog/gaat bewegen en tenslotte hoe het er gedurende het jaar uitziet rond 10:00 uur.',
      'Kun je ontdekken of de schaduw van De Rotterdam de overkant van de Maas kan halen?',
    ],
    'hints': [
      {
        'description': "Belangrijkste is hier dat je weet dat 'controls' op meerdere plekken (kunnen) zitten in de viewer. Voor deze oefening moet je rechtsboven zijn:",
        'img': '/exercises/nlin3d/rotterdam/schaduw/Schaduw_control.png',
      },
      {
        'description': 'Nu de schaduw (hopelijk) aan staat, kun je met het instellingsknopje parameters gaan veranderen:',
        'img': '/exercises/nlin3d/rotterdam/schaduw/Schaduw_instellingen.png',
      },
      {
        'description': "En vergeet niet de 'schaduw player' af te spelen. Je kunt de instellingen tijdens het afspelen nog verschuiven.",
        'img': '/exercises/nlin3d/rotterdam/schaduw/Schaduw_player.png',
      },
    ],
  },
  {
    id: 'rotterdam-ondergrond',
    'title': 'Ondergrond',
    'subtitle': 'Is er nog ruimte voor de mollen?',
    'img': '/exercises/nlin3d/rotterdam/ondergrond/Ondergrond.png',
    'rating': 5,
    mapConfig: 'rotterdam_full',
    customCss: '/templates/rotterdam.css',
    'tags': [
      'Rotterdam',
      'Tools',
      'LVZK',
    ],
    'description': [
      'Als je over straat loopt, zie je in één oogopslag hoe vol de buitenruimte is. Maar hoe zit dat onder de grond? Kun je zomaar een verkeersbord neerzetten zonder iets te raken?',
      'Er zijn verschillende manieren om de ondergrond te bekijken. Je kunt het terrein transparant maken of een gat in de grond graven.',
      'Zijn er naast de kabels en leidingen nog andere objecten met een ondergronds deel?',
    ],
    'hints': [
      {
        'description': 'Kijk bij de tools welke opties je hebt om onder de grond te kijken.',
        'img': '/exercises/nlin3d/rotterdam/ondergrond/Ondergrond_tool.png',
      },
      {
        'description': 'Als je niets onder de grond ziet, moeten er misschien nog wat kaartlagen aangezet worden.',
        'img': '/exercises/nlin3d/rotterdam/ondergrond/Ondergrond_lagen.png',
      },
      {
        'description': 'Als een echte mol kun je zelf ook onder de grond. Dit doe je in de transparante terrein modus.',
        'img': '/exercises/nlin3d/rotterdam/ondergrond/Ondergrond_transparant.png',
      },
    ],
  },
  {
    id: 'rotterdam-zicht',
    'title': 'Zichtbaarheid',
    'subtitle': 'Kun je het Eramus MC zien vanaf de Erasmusbrug?',
    'img': '/exercises/nlin3d/rotterdam/zicht/Zicht.png',
    'rating': 3,
    mapConfig: 'rotterdam_full',
    customCss: '/templates/rotterdam.css',
    'tags': [
      'Rotterdam',
      'Tools',
    ],
    'description': [
      'Als je zelf op de Erasmusbrug staat weet je wel wat je allemaal ziet. Maar dit kun je ook simuleren in een 3D omgeving.',
      'Met de zichtanalyse tool kun je op elke locatie in 3D Rotterdam bekijken wat er allemaal zichtbaar is.',
      'Kun je het Erasmus MC zien vanaf de Erasmusbrug? En hoe zit dat met de Delftse Poort? En het stadhuis?',
    ],
    'hints': [
      {
        'description': 'Bij de tools is er een optie om een zichtanalyse uit te voeren.',
        'img': '/exercises/nlin3d/rotterdam/zicht/Zicht_tool.png',
      },
      {
        'description': 'Waarschijnlijk is de afstand die je kunt analyseren nog te klein. Deze kun je tot 2000m uitbreiden.',
        'img': '/exercises/nlin3d/rotterdam/zicht/Zicht_instellingen.png',
      },
    ],
  },
  {
    id: 'schaduw',
    title: 'Schaduw',
    subtitle: 'Zet de Martinitoren in de schaduw',
    img: '/exercises/nlin3d/schaduw/schaduw_groningen.png',
    rating: 1,
    mapConfig: 'schaduw',
    customCss: '/templates/groningen.css',
    tags: [
      'Groningen',
      'Tools',
    ],
    description: [
      'Schaduwanalyses zijn eenvoudig te doen binnen een 3D omgeving. Laten we in dit geval eens kijken hoe de schaduw van de Martinitoren gedurende het jaar valt.',
      'Begin maar eens te kijken hoe de schaduw er vandaag om 10:00 uur uitzag.',
      'Bekijk vervolgens hoe de schaduw gedurende vandaag bewoog/gaat bewegen en tenslotte hoe het er gedurende het jaar uitziet rond 10:00 uur.',
    ],
    hints: [
      {
        'description': "De Martinitoren staat in Groningen... Je bent toch niet aan het kijken naar de kerk van Zwolle? 'Zoeken' gaat misschien wel het snelst..",
      },
      {
        'description': "Belangrijkste is hier dat je weet dat 'controls' op meerdere plekken (kunnen) zitten in de viewer. Voor deze oefening moet je rechtsboven zijn:",
        'img': '/exercises/nlin3d/schaduw/shadow_button.png',
      },
      {
        'description': 'Nu de schaduw (hopelijk) aan staat, kun je met het instellingsknopje parameters gaan veranderen:',
        'img': '/exercises/nlin3d/schaduw/shadow_settings_button.png',
      },
      {
        'description': "En vergeet niet de 'schaduw player' af te spelen. Je kunt de instellingen tijdens het afspelen nog verschuiven.",
        'img': '/exercises/nlin3d/schaduw/shadow_player.png',
      },
    ],
  },
  {
    id: 'tools-meten',
    title: 'Meten is weten',
    subtitle: 'Kan een vrachtwagen onder de Liefdesbrief door?',
    img: '/exercises/nlin3d/liefdesbrief/liefdesbrief.png',
    rating: 3,
    mapConfig: 'liefdesbrief',
    customCss: '/templates/nederlandin3d.css',
    tags: [
      'NLin3D',
      'Tools',
    ],
    description: [
      "Vrachtwagens in Nederland zijn maximaal 4 meter hoog. Als het goed is zijn viaducten dus hoger... Laten we eens kijken of dat in het geval van 'de Liefdesbrief' ook zo is.",
      "Gebruik hiervoor in eerste instantie de 'hoogteprofiel-tool' en verifieer het door het juiste te meten met de 'meten-tool'",
      "Als dit toch al makkelijk gaat, hoe hoog is de torenspits van 'Nieuwe Kerkhof 1' in Groningen? En hoeveel m2 is Nieuwe Kerkhof (OpenStreetMap, groen)? En hoe lang is de schaduw van de torenspits op 30 augustus 2022 om 12:00 uur 's middags?",
    ],
    hints: [
      {
        'description': 'De Liefdesbrief is een viaduct in de buurt van Ikea Delft...',
        'img': '',
      },
      {
        'description': "Vaak geeft de OpenStreetMap kaart wat meer informatie dan de luchtfoto. Zet de OpenStreetMap laag aan (via 'Inhoud') en zoom maar eens in.",
        'img': '',
      },
      {
        'description': "Een andere optie is  om middels 'Zoeken' gewoon naar 'liefdesbrief' te zoeken",
        'img': '',
      },
      {
        'description': 'Als het goed is heb je de Liefdesbrief nu gevonden. Selecteer de hoogteprofiel tool via het toolsmenu en volg de tips die verschijnen. En? Is de Liefdesbrief hoger dan 4 meter bij de rijbanen?',
        'img': '/exercises/nlin3d/liefdesbrief/toolsmenu.png',
      },
      {
        description: "Let op! Binnen de 'hoogteprofiel-tool' kun je ook nog meten!",
        img: '/exercises/nlin3d/liefdesbrief/hoogteprofiel.png',
      },
      {
        'description': 'Een andere optie is om een meting te doen. Selecteer de meet-tool via het toolsmenu en voer de juiste meting uit. Klopt het resultaat met de vorige meting?',
        'img': '',
      },
    ],
  },
  {
    id: 'planner-bim',
    title: 'Plannen',
    subtitle: 'Plaats het onbrekende gebouw',
    img: '/exercises/nlin3d/bim/schependom_bim.png',
    rating: 5,
    mapConfig: 'bim',
    customCss: '/templates/nederlandin3d.css',
    tags: [
      'NLin3D',
      'Tools',
      'Planner',
    ],
    description: [
      'Iedereen die iets met BIM doet kent \'Schependomlaan 22\'.',
      'Maar niet iedereen weet dat dit voorbeeld-gebouw ook daadwerkelijk is gebouwd! Het zit echter nog niet in onze 3D Bag laag. Tijd om daar verandering in aan te brengen met behulp van de plannings module in de 3D viewer.',
    ],
    hints: [{
      description: 'Download als eerste in ieder geval Schependomlaan (GLTF) hierboven. Deze gaan we (proberen te) plaatsen op de juiste plek in Nijmegen. In het tools-menu is nu alleen de planner beschikbaar. Klik deze aan en log in met gebruikersnaam: workshop@nederlandin3d.nl en het verstrekte wachtwoord.',
      img: '/exercises/nlin3d/bim/planner_button.png',
    }, {
      description: 'Na het inloggen, kun je een nieuw project aanmaken en andere projecten zien. Maak er eentje aan met een herkenbare naam. Iets als [voornaam]-Schependom oid.',
      img: '/exercises/nlin3d/bim/new_project.png',
    }, {
      description: "Klik op het potloodje om het project te bewerken. Vervolgens kan via 'Planning object importeren' het zojuist gedownloade model geupload worden.",
      img: '/exercises/nlin3d/bim/uploaded.png',
    }, {
      description: 'Er staat een waarschuwing bij het model. Het model kon namelijk niet automatisch geplaatst worden. Klik op het model om het vervolgens met een klik op de kaart op de juiste plek te zetten.',
      img: '/exercises/nlin3d/bim/place_model.png',
    }, {
      description: 'Nadat het model geplaatst is, staat het nog niet helemaal correct. Gebruik de diverse tools om dit te veranderen. Vergeet niet de wijzigingen op te slaan.',
      img: '/exercises/nlin3d/bim/place_tools.png',
    }, {
      description: 'Het precies plaatsen is best lastig. Een rotatie van 70 ziet er goed uit, en hier zijn wat instellingen voor de locatie.',
      img: '/exercises/nlin3d/bim/coordinates.png',
    }, {
      description: 'Om het af te maken kun je een standaard zichtpunt aanmaken. Volg de aanwijzingen. Als het goed is, ziet het er nu ongeveer uit als dit voorbeeld.',
      img: '/exercises/nlin3d/bim/viewpoint.png',
    }, {
      description: 'En zo ziet Schependomlaan 22 er in het echt uit:',
      img: '/exercises/nlin3d/bim/schependom_real.png',
    }],
    downloads: [{
      name: 'Schependom (GLTF)',
      url: '/exercises/nlin3d/bim/models/schependomlaan_gltf.glb',
    }, {
      name: 'Simpel huis (GLTF)',
      url: '/exercises/nlin3d/bim/models/simpel_huis.glb',
    }, {
      name: 'Huis (GLTF)',
      url: '/exercises/nlin3d/bim/models/huis.glb',
    }],
  },
  {
    hide: true,
    id: 'samenwerken',
    title: 'Samenwerken',
    subtitle: 'Plaats het gebouw',
    img: '/exercises/nlin3d/samenwerkworkshop/aelbrecht.png',
    rating: 5,
    mapConfig: 'samenwerkworkshop',
    customCss: '/templates/rotterdam.css',
    tags: [
      'NLin3D',
      'Tools',
      'Planner',
    ],
    description: [
      'In deze opdracht is het de bedoeling om een bestaand gebouw te vervangen door een BIM model.',
      'Probeer het zonder teveel hints voor elkaar te krijgen door middel van de planningsmodule.',
    ],
    hints: [{
      description: 'Download als eerste in ieder geval Aelbrecht (GLTF) hierboven. Deze gaan we (proberen te) plaatsen op de juiste plek in Rotterdam. In het tools-menu is nu alleen de planner beschikbaar. Klik deze aan en log in met gebruikersnaam: workshop@nederlandin3d.nl en het verstrekte wachtwoord.',
      img: '/exercises/nlin3d/samenwerkworkshop/planner_button.png',
    }, {
      description: 'Na het inloggen, kun je een nieuw project aanmaken en andere projecten zien. Maak er eentje aan met een herkenbare naam. Iets als [voornaam]-Aelbrechtskade oid.',
      img: '/exercises/nlin3d/samenwerkworkshop/new_project.png',
    }, {
      description: "Klik op het potloodje om het project te bewerken. Vervolgens kan via 'Planning object importeren' het zojuist gedownloade model geupload worden.",
      img: '/exercises/nlin3d/samenwerkworkshop/uploaded.png',
    }, {
      description: 'Het model is nog niet geplaatst (zie volgende hint). Maar er staan reeds objecten op de plaats waar we het model willen hebben. Deze moeten wellicht eerst verborgen worden...',
      img: '/exercises/nlin3d/samenwerkworkshop/hide_objects.png',
    }, {
      description: 'Er staat een waarschuwing bij het model. Het model kon namelijk niet automatisch geplaatst worden. Klik op het model om het vervolgens met een klik op de kaart op de juiste plek te zetten.',
      img: '/exercises/nlin3d/samenwerkworkshop/place_model.png',
    }, {
      description: 'Nadat het model geplaatst is, staat het nog niet helemaal correct. Gebruik de diverse tools om dit te veranderen. Vergeet niet de wijzigingen op te slaan.',
      img: '/exercises/nlin3d/samenwerkworkshop/place_tools.png',
    }, {
      description: 'Het precies plaatsen is best lastig. Een rotatie van 90 ziet er goed uit, en hier zijn wat instellingen voor de locatie.',
      img: '/exercises/nlin3d/samenwerkworkshop/coordinates.png',
    }, {
      description: 'Om het af te maken kun je een standaard zichtpunt aanmaken. Volg de aanwijzingen. Als het goed is, ziet het er nu ongeveer uit als dit voorbeeld.',
      img: '/exercises/nlin3d/samenwerkworkshop/viewpoint.png',
    }, {
      description: 'De planning staat overigens ook in de legenda. (als je tenminste ingelogd bent).',
      img: '/exercises/nlin3d/samenwerkworkshop/legend.png',
      bonus: true,
    }],
    downloads: [{
      name: 'Aelbrecht (GLTF)',
      url: '/exercises/nlin3d/samenwerkworkshop/models/aelbrecht.glb',
    }],
  },
  {
    id: 'bim-haringstraat',
    title: 'BIM',
    subtitle: 'Bekijk een BIM model',
    img: '/exercises/nlin3d/haringstraat/haringstraat.png',
    rating: 2,
    mapConfig: 'bimdenhaag',
    customCss: '/templates/denhaag.css',
    tags: [
      'Den Haag',
      'BIM',
      'Plugin',
    ],
    description: [
      'De kaart ondersteunt ook plugins. In dit geval worden twee BIM modellen beschikbaar gemaakt via een plugin. Kijk maar eens naar de Haringstraat.',
    ],
    hints: [{
      description: "Zet eerst het BIM model aan via 'Inhoud', anders wordt het sowieso lastig.",
    }, {
      description: 'Het BIM model is wat lastig te zien met al die andere gebouwen in de buurt. Misschien moeten die even uit.',
      img: '/exercises/nlin3d/haringstraat/hidden.png',
    }, {
      description: 'Zelfs zonder omringende gebouwen valt het BIM model weg tegen de achtergrond. Misschien met een andere achtergrond?',
      img: '/exercises/nlin3d/haringstraat/background.png',
    }],
  },
  {
    id: 'instellingen',
    title: 'Instellingen',
    subtitle: 'Kijk eens door een gekleurde bril...',
    img: '/exercises/nlin3d/settings/gekleurdebril.png',
    rating: 1,
    mapConfig: 'instellingen',
    customCss: '/templates/nederlandin3d.css',
    tags: [
      'NLin3D',
      'Gadget',
    ],
    description: [
      'De 3D kaart is op verschillende manieren te bekijken. Hoe ziet de wereld er uit in zwart/wit? Of door een nachtkijker? En wat gebeurt er als je de kwaliteit wat lager zet?',
      'Vragen, vragen, vragen..',
    ],
    hints: [{
      description: 'Het begint allemaal met \'Instellingen\'',
      img: '/exercises/nlin3d/settings/instellingen.png',
    }, {
      description: 'De rest zou eigenlijk voor zich moeten spreken. Probeer gewoon wat uit..',
      img: '/exercises/nlin3d/settings/glow.png',
    }],
  },
  {
    id: 'sensoren',
    title: 'Sensoren',
    subtitle: 'Is de ring Rotterdam een beetje te doen op dit moment',
    img: '/exercises/nlin3d/sensoren/sensoren.png',
    rating: 2,
    mapConfig: 'sensoren',
    customCss: '/templates/rotterdam.css',
    customJs: '/templates/sensoren.js',
    tags: [
      'Rotterdam',
      'Ruggedised',
      'Plugin',
    ],
    description: [
      'Deze viewer maakt gebruik van een plugin om enkele sensor-streams te visualizeren. Zie hoe druk het verkeer is, hoe vol de afvalbakken zitten en of de bussen een beetje op tijd rijden...',
      'En? Hoe druk is het op de ring van Rotterdam?',
    ],
    hints: [{
      description: "Via 'Inhoud' zijn de sensoren te activeren. Het kan even duren voordat bijvoorbeeld de prullenbakken getoond worden. Is er niks te zien in de 'Inhoud'? Refresh dan de pagina (ctrl+r) en probeer de opgave opnieuw.",
      img: '/exercises/nlin3d/sensoren/inhoud.png',
    }, {
      description: 'Met alle sensoren en gebouwen aan, is het overzicht al snel kwijt. Misschien is het handiger om deze kaart in 2D te bekijken? De sensor data leent zich ook prima voor de 2D kaart namelijk.',
      img: '/exercises/nlin3d/sensoren/2D.png',
    }, {
      description: 'Als het goed is, ziet het er nu ongeveer zo uit:',
      img: '/exercises/nlin3d/sensoren/result.png',
    }, {
      description: 'En van elke sensor is data op te vragen:',
      img: '/exercises/nlin3d/sensoren/sensordata.png',
    }],
  },
  {
    id: 'tekenen',
    title: 'Tekenen in 3D',
    subtitle: 'Teken een parkeergarage voor Future Insight',
    img: '/exercises/nlin3d/parkeergarage/parkeergarage.png',
    rating: 4,
    mapConfig: 'parkeergarage',
    customCss: '/templates/nederlandin3d.css',
    tags: [
      'NLin3D',
      'Tools',
    ],
    description: [
      'Future Insight is booming. Tijd om een parkeergarage in de buurt van het kantoor te hebben.',
      'Probeer er eentje te tekenen, net als in het voorbeeld.',
    ],
    hints: [{
      description: "Het kantoor van Future Insight is makkelijk te vinden via de 'Inhoud'..",
    }, {
      description: 'In dit geval is er maar 1 tool beschikbaar. De teken-tool:',
      img: '/exercises/nlin3d/parkeergarage/tekentool.png',
    }, {
      description: 'Tekenen in 3D is best lastig. Bekijk de wereld eens van boven.',
      img: '/exercises/nlin3d/parkeergarage/topview.png',
    }, {
      description: "Elke parkeergarage begint met een grondvlak. Dat we later uitrekken naar boven. Selecteer de 'vlak-tool':",
      img: '/exercises/nlin3d/parkeergarage/vlaktool.png',
    }, {
      description: 'Dubbelklik bij het zetten van het laatste punt, of klik op het vinkje om het vlak af te maken:',
      img: '/exercises/nlin3d/parkeergarage/vinkje.png',
    }, {
      description: "Selecteer het zojuist getekende vlak door de 'selectie-tool' te gebruiken en het vlak aan te klikken:",
      img: '/exercises/nlin3d/parkeergarage/selectie.png',
    }, {
      description: 'Trek het model uit, plaats het op het maaiveld, verander de kleur etc. Als het goed is, staat er nu iets wat zomaar eens een parkeergarage kan worden.',
    }],
  },
  {
    id: 'attributen',
    title: 'Attributen',
    subtitle: 'Wanneer is de Euromast eigenlijk gebouwd?',
    img: '/exercises/nlin3d/attributen/euromast.png',
    rating: 1,
    mapConfig: 'attributen',
    customCss: '/templates/rotterdam.css',
    tags: [
      'Rotterdam',
      'Attributen',
    ],
    description: [
      'Één van de basisfuncties van een 3D digital twin is het beschikbaar maken van attributen van 3D modellen. In dit geval gaan we op zoek naar het bouwjaar van de Euromast. Of is dat gewoon parate kennis?',
    ],
    hints: [{
      description: "Begin maar eens met het 'Zoeken' naar de Euromast.",
    }, {
      description: "In tegenstelling tot de Hef (een brug), is de Euromast is een gebouw, voorlopig staan die wellicht nog niet aan. Kijk eens bij 'Inhoud'.",
    }, {
      description: 'Geven all gebouw lagen hetzelfde resultaat? Hopelijk wel, want het is alleen telkens een andere representatie van dezelfde data!',
      img: '/exercises/nlin3d/attributen/layers.png',
    }, {
      description: 'Volgens de pop-up is de Euromast in 1961 gebouwd. Volgens Wikipedia een iets eerder trouwens :-)',
      img: '/exercises/nlin3d/attributen/euromast_wiki.png',
    }],
  },
  {
    id: 'ondergrond',
    title: 'Ondergrond',
    subtitle: 'Hoe diep kunnen we gaan?',
    img: '/exercises/nlin3d/ondergrond/ondergrond.png',
    rating: 5,
    mapConfig: 'ondergrond',
    customCss: '/templates/rotterdam.css',
    tags: [
      'Rotterdam',
      'Tools',
      'BRO',
    ],
    description: [
      'Een probleem met dingen onder de grond is dat ze onder de grond liggen. En dus wat lastig zichtbaar zijn. Toch gaan we in deze opgave eens kijken of we er achter kunnen komen of er in Rotterdam grondboringen zijn gedaan dieper dan 250 meter!',
    ],
    hints: [{
      description: "In deze viewer zijn een hoop interessante zaken onder de grond verstopt. Begin maar eens met 'Snel naar' een 'diepe grondboring' te gaan.",
      img: '/exercises/nlin3d/ondergrond/viewpoint.png',
    }, {
      description: "Hier is nog niet zoveel te zien. Vergeet niet de 'grondboringen laag' aan te zetten! En misschien moet het terrein even transparant gemaakt worden. En activeer de camera onder het terrein zodat je de wereld ook vanaf de onderkant kunt bekijken.",
      img: '/exercises/nlin3d/ondergrond/transparent_terrain.png',
    }, {
      description: 'De grondboring is nu goed te zien. Maar is hij dieper dan 250 meter? Meten is weten, maar ook lastig onder de grond. Probeer het toch maar eens.',
      img: '/exercises/nlin3d/ondergrond/measurement.png',
    }, {
      description: 'Als het goed is, is de grondboring +/-252 meter. Maar dit kan makkelijker. Als we het terrein 250 meter laten zakken, en de grondboring steekt dan alsnog door het terrein, dan is hij blijkbaar dieper dan 250 meter!',
      img: '/exercises/nlin3d/ondergrond/lower_terrain.png',
    }, {
      description: 'Lastig te zien wellicht, maar de grondboring steekt door het verlaagde terrein heen. Mission accomplished.',
      img: '/exercises/nlin3d/ondergrond/poke_through.png',
    }, {
      description: 'En kijk rustig verder naar de ondergrondse wereld. Bijvoorbeeld bij archeologie.',
      img: '/exercises/nlin3d/ondergrond/archeologie.png',
    }],
  },
  {
    id: 'waar-is-wally',
    title: 'Nederland in 3D',
    subtitle: 'Waar is Wally?',
    img: '/exercises/nlin3d/zoeknederland/nederlandin3d.png',
    rating: 5,
    mapConfig: 'waariswally',
    customCss: '/templates/nederlandin3d.css',
    tags: [
      'NLin3D',
    ],
    description: [
      'Ergens in Nederland ligt een vijver in de vorm van Nederland, maar waar?',
      'Deze is haast onmogelijk om \'op te lossen\' als je niet weet waar je moet zoeken. Maar misschien heb je geluk? Of volg wat hints...',
    ],
    hints: [{
      description: 'Deze is ooit gevonden bij de eerste 3D Digital Twin klant van Future Insight. Redelijk in de randstad dus.',
    }, {
      description: 'De A15 ligt aardig in de buurt.',
      img: '/exercises/nlin3d/zoeknederland/a15.png',
    }, {
      description: "Dit soort gebouwen zijn in de buurt te vinden (check ook de 'Inhoud'):",
      img: '/exercises/nlin3d/zoeknederland/opslagtanks.png',
    }, {
      description: 'Er is ook een heuvel in de buurt waar je kunt \'relaxen\'. Een soort \'relax-heuvel\'. Hierbij helpt het als je Engels spreekt. En kunt rijmen.',
    }, {
      description: 'Over Engels gesproken; \'New England\' ligt in de buurt. Hierbij helpt het weer als je Nederlands spreekt.',
    }, {
      description: 'Ok, zoek eens op \'Nieuw Engeland\' of op \'Chill-Hill\'. Nu zou je er toch echt moeten zijn.',
      img: '/exercises/nlin3d/zoeknederland/chillhill.png',
    }],
  },
];

const mapped = exercises.map((ex: any) => ({
  ...ex,
  config: mapConfigs[ex.mapConfig as never],
}));

export default mapped;
