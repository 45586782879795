import { Config } from './config';

const config: Config = {
  title: 'E-Learning',
  titleSub: 'T3D',
  name: 'totaal3d',
  greeting: 'Welkom bij de e-learning van de T3D Proefopstelling!',
  showLogo: false,
  showLinkToViewers: false,
};

export default config;
