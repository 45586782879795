import { default as nlin3d } from './nlin3d';
import { default as totaal3d } from './totaal3d';

import conf from '../config';

const exercises = conf.name === 'totaal3d'
  ? totaal3d
  : conf.name === 'nlin3d'
    ? nlin3d
    : [];

export default exercises;
