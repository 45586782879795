import { Config } from './config';

import { default as nlin3d } from './nlin3d';
import { default as totaal3d } from './totaal3d';

const mapping: { [key: string]: Config } = {
  'workshop.nederlandin3d.nl': nlin3d,
  'e-learning.totaal3d.nl': totaal3d,
  'localhost': totaal3d,
};

const { hostname } = window.location;

const config = mapping[hostname] || nlin3d;

export default config;
