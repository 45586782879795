import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';

import './index.css';
import themes from './theme';

import Home from './components/Home';
import App from './components/App';

import {
  HashRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import VcMap from './components/VcMap';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(<>
  <ThemeProvider theme={themes.light}>
    <Router>
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<App />} />
        {/* <Route path="/iframe" element={<div>goat</div>} /> */}
        <Route path="/map/:id" element={<VcMap />} />
        <Route path="/map/:id" element={<VcMap />} />
      </Routes>
    </Router>
  </ThemeProvider>
</>);
