/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import HomeIcon from '@mui/icons-material/Home';
import HelpIcon from '@mui/icons-material/Help';
import DescriptionIcon from '@mui/icons-material/Description';

import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import DoneIcon from '@mui/icons-material/Done';
import StarIcon from '@mui/icons-material/Star';

import { useParams, useNavigate } from 'react-router-dom';

import exercises from '../exercises';
import config from '../config';

const drawerWidth = 380;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(): React.ReactElement | null {

  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [hintsOpen, setHintsOpen] = React.useState<any>();
  const [exercise, setExercise] = React.useState<any>();


  React.useEffect((): void => {
    const ex = exercises.find((e) => e.id === id);
    if (!ex) {
      navigate('/');
    } else {
      setExercise(ex);
      setHintsOpen(ex.hints.reduce((p: any, c: any, idx: any) => ({
        ...p,
        [idx]: false,
      }), {
        desc: true,
      }));
    }
  }, [exercises]);


  const handleDrawerOpen = (): void => {
    setOpen(true);
  };

  const handleDrawerClose = (): void => {
    setOpen(false);
  };

  const icons = [
    <Filter1Icon />,
    <Filter2Icon />,
    <Filter3Icon />,
    <Filter4Icon />,
    <Filter5Icon />,
    <Filter6Icon />,
    <Filter7Icon />,
  ];

  // React.useEffect(() => {
  //   setTimeout(() => { setOpen(false); }, 5000);
  // }, []);

  const handleHintToggle = (idx: any) => () => {
    const newState = exercise.hints.reduce((p: any, c: any, i: any) => ({
      ...p,
      [i]: false,
    }), {});
    newState[idx] = !hintsOpen[idx];
    setHintsOpen(newState);
  };

  if (!exercise) return null;

  const getIcon = (index: number): React.ReactElement => {
    const lastIdx = exercise.hints.length - 1;
    if (exercise.hints[lastIdx].bonus) {
      if (index === lastIdx) {
        return <StarIcon />;
      } else if (index === lastIdx - 1) {
        return <DoneIcon />;
      } else {
        return icons[index];
      }
    } else {
      if (index === lastIdx) {
        return <DoneIcon />;
      } else {
        return icons[index];
      }
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <HelpIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {config.title}
          </Typography>
          <Link href="/" style={{ color: 'white' }} title="Terug naar de opgaven">
            <IconButton
              color="inherit"
              edge="end"
            >
              <HomeIcon />
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <ListSubheader>{`Opdracht: ${exercise.title}`}</ListSubheader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List disablePadding>
          <ListItem>
            <Button
              startIcon={<HomeIcon />}
              href="/"
              variant="contained"
              color="primary"
              fullWidth>
              Terug naar de opgaven
            </Button>
          </ListItem>
          <Divider />
          <ListItemButton alignItems="flex-start" onClick={handleHintToggle('desc')}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={exercise.subtitle} />
            {hintsOpen.desc ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={hintsOpen.desc} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem>
                <ListItemText sx={{ pl: 2, pr: 2 }}
                  secondary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                    >
                      <>
                        {exercise.description.map((desc: string, idx: number) => (
                          <React.Fragment key={idx}>
                            <span dangerouslySetInnerHTML={{ __html: desc }} />
                            <div style={{ padding: 8 }} />
                          </React.Fragment>))}
                        <Paper
                          elevation={4}
                          style={{
                            width: '100%',
                            maxHeight: 300,
                            padding: 5,
                          }}>
                          <img
                            src={`${exercise.img}`}
                            style={{
                              width: '100%',
                              maxHeight: 290,
                            }}
                          />
                        </Paper>
                      </>
                    </Typography>
                  }
                />
              </ListItem>
              {exercise.downloads
                ? (
                  <ListItem>
                    <ListItemText
                      sx={{ pl: 2, pr: 2 }}
                      primary={
                        <Typography variant="body1">
                          Download voorbeeldbestand(en).
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" component="span">
                          <ul>
                            {exercise.downloads.map((download: any) => (
                              <li key={download.name} style={{ marginBottom: 4 }}>
                                <Link
                                  target="_blank"
                                  download
                                  href={download.url}
                                >
                                  {download.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </Typography>
                      } />
                  </ListItem>
                )
                : null}
            </List>
          </Collapse>
          <Divider sx={{ mt: 1 }} />
          {exercise.hints.map((hint: any, index: number) => (
            <React.Fragment key={index}>
              <ListItemButton onClick={handleHintToggle(index)}>
                <ListItemIcon>
                  {getIcon(index)}
                </ListItemIcon>
                <ListItemText primary={hint.bonus ? 'Extra' : `Hint / Stap ${index + 1}`} />
                {hintsOpen[index] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={hintsOpen[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemText sx={{ pl: 2, pr: 2 }}
                    primary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                      >
                        {hint.description}
                      </Typography>
                    } secondary={
                      <Typography
                        component="span"
                        style={{ textAlign: 'center', display: 'block' }}>
                        {hint.img
                          ? (<>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <Paper elevation={3} style={{ padding: 8 }}>
                              <img src={hint.img} style={{ maxHeight: 200, maxWidth: '100%' }} />
                            </Paper>
                          </>)
                          : null
                        }
                      </Typography>
                    }
                  />
                </List>
              </Collapse>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      <Main open={open} style={{ padding: 0 }}>
        <DrawerHeader />

        <iframe
          title="viewer"
          src={`/#/map/${id}`}
          style={{
            width: '100%',
            height: 'calc(100vh - 70px)',
            border: 'none',
          }}
          name="__viewer__iframe__"
        />
      </Main>
    </Box >
  );
}
